



























import { defineComponent } from '@vue/composition-api';
import { useDoc } from '@/module/doc';

export default defineComponent({
  name: 'Faq',
  setup() {
    const { items } = useDoc('category=faq');

    return {
      items,
      title: 'Frequently Asked Questions',
      noDataMessage: `Shouldn't you add some data`
    };
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [_c("ref-subtitle", [_vm._v(_vm._s(_vm.title))])],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.items
                ? _c(
                    "v-expansion-panels",
                    { attrs: { focusable: "" } },
                    _vm._l(_vm.items, function(f, i) {
                      return _c(
                        "v-expansion-panel",
                        { key: i },
                        [
                          _c("v-expansion-panel-header", [
                            _c("h3", [_vm._v(_vm._s(f.title))])
                          ]),
                          _c("v-expansion-panel-content", [
                            _c("p", { staticClass: "mt-6" }, [
                              _vm._v(" " + _vm._s(f.content) + " ")
                            ])
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("ref-body-text", [
                    _vm._v(" " + _vm._s(_vm.noDataMessage) + " ")
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }